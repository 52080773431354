import MegaMenuItem from './megamenuItem';

export default class MegaMenu {
    constructor(el) {
        this.$el = el;
        this.$megaMenuItems = this.$el.querySelectorAll(
            '[data-megamenu-list-item]'
        );
        this.megaMenuItemsLength = this.$megaMenuItems.length - 1;
        this.isLastItem = false;
        this.init();
    }

    init() {
        this.$megaMenuItems.forEach((item, index) => {
            if (this.megaMenuItemsLength === index) {
                this.isLastItem = true;
            }
            // eslint-disable-next-line no-new
            new MegaMenuItem(item, this.isLastItem);
        });
    }
}
